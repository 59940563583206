<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div class="mh-100vh bg-white content-page" v-else>
      <RegisterFormComponent
        :form="form"
        :v="$v"
        @checkForm="checkForm"
        title="สมัครสมาชิก"
        :isDisplayTelephone="true"
        :disableBtn="disableBtn"
        :customField="customField"
        :dynamicField="dynamicField"
        :questionList="questionList"
        :chkBirthDay="chkBirthDay"
        :branchList="branchList"
        :is_privacy_policy1="is_privacy_policy1"
        :fieldInForm="fieldInForm"
      />
    </div>
    <ModalError ref="modalError" :text="successMessage" />
  </div>
</template>

<script>
import ModalError from "@/components/alert-modal/ModalError";
import RegisterFormComponent from "@/components/register/RegisterFormComponent";
import {
  required,
  helpers,
  email,
  minLength,
  maxLength,
  numeric,
  requiredIf
} from "vuelidate/lib/validators";
import OtherLoading from "@/components/other/OtherLoading";
import { fieldInForm, profile_fields } from "@/assets/json/fieldProfile.json";
var profile_field = { ...profile_fields };
const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);
const moment = require("moment");
const Script_checkID = id => {
  let i = 0,
    sum = 0;

  if (id.substring(0, 1) == 0) return false;
  for (i = 0, sum = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i);
  if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) return false;
  return true;
};
export default {
  components: {
    ModalError,
    RegisterFormComponent,
    OtherLoading
  },
  data() {
    return {
      form: {
        registerTypeId: 2,
        SocialProvider: "line",
        SocialId: "",
        password: "",
        display_name: "",
        branch_id: "0",
        t_datetime: "",
        line_id: "",
        the_one_card_member: "",
        line_ref: "",
        accept_terms_and_conditions: 1,
        is_consent: 0,
        is_privacy_policy: 0,
        ...profile_field
      },
      successMessage: "",
      errorBirthDate: "",
      validateBirthDate: false,
      isLoading: true,
      disableBtn: false,
      customField: [],
      dynamicField: [],
      questionList: [],
      user_guid: "",
      chkBirthDay: process.env.VUE_APP_BIRTHDAY,
      is_privacy_policy1: 0,
      branchList: [],
      fieldInForm: [...fieldInForm]
    };
  },
  beforeCreate: async function () {
    if (!this.$liff.isInClient()) {
      this.$liff.init({ liffId: this.$liff_ID_Login });
    }
  },
  async created() {
    // if (!this.$cookies.get("hourglass_register_success")) {
    this.$liff.ready
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.form.SocialId = profile.userId;
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.form.SocialId = profile.userId;
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
    await this.getCustomField();
    // } else {
    //   if (this.$route.query.redirect)
    //     return this.$router.push({
    //       path: this.$route.query.redirect,
    //       query: { ...this.$route.query }
    //     });
    //   this.$router.push("/profile");
    // }
  },

  validations() {
    for (const item of this.customField) {
      const key = this.checkIsProfile(item.field_profile_type_id);

      if (key) {
        if (item.required) {
          if (item.field_profile_type_id == 11) {
            profile_fields[key] = { required, email };
          } else if (item.field_profile_type_id == 10)
            profile_fields[key] = {
              required,
              maxLength: maxLength(10),
              minLength: minLength(10),
              numeric
            };
          else if (item.field_profile_type_id == 9) {
            profile_fields["birthday_day"] = {
              required
            };
            profile_fields["birthday_month"] = {
              required
            };
            profile_fields["birthday_year"] = {
              required
            };
          } else if (item.field_profile_type_id == 1)
            profile_fields[key] = {
              required,
              Script_checkID,
              maxLength: maxLength(13),
              minLength: minLength(13),
              numeric
            };
          else profile_fields[key] = { required };
        } else {
          profile_fields[key] = {};
        }
      }
    }
    return {
      form: profile_fields,
      dynamicField: {
        $each: {
          user_answer: {
            required: requiredIf(el => el.required)
          }
        }
      }
    };
  },
  methods: {
    async getCustomField() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/user/registerform/1`)
        .then(async data => {
          this.isLoading = false;
          if (data.result === 1) {
            this.form.telephone = this.$route.query.tel;
            this.customField = data.detail.result.fields;
            for (const field of data.detail.result.custom_field) {
              // let form = this.form.custom_field.find(el => el.id == field.id);

              // let value = form.user_answer.map(el => el.answer);

              if (field.field_type_id == 2) {
                field.user_answer = [];
              }
              if (field.field_type_id == 6) {
                this.getBranch(field.branch_group_id);
              }
            }
            this.dynamicField = data.detail.result.custom_field.filter(
              el => el.is_show == 1
            );
          }
        });
    },
    checkIsProfile(val) {
      const findField = fieldInForm.find(el => el.id == val);
      return findField ? findField.key : false;
    },
    checkForm: async function (val) {
      this.form = val.form;

      if (this.form.is_consent) {
        this.form.is_consent = 1;
      } else {
        this.form.is_consent = 0;
      }
      this.$v.form.$touch();

      this.$v.dynamicField.$touch();
      if (this.$v.dynamicField.$error || this.$v.form.$error) {
        return;
      }
      if (!this.form.birthday_day) this.form.birthday_day = "01";
      if (!this.form.birthday_month) this.form.birthday_month = "01";
      if (!this.form.birthday_year) this.form.birthday_year = "1753";
      this.isLoading = true;
      delete this.form.birthday;
      let dynamic = [];
      for (const fields of this.dynamicField) {
        let target_id = 0;
        if (fields.field_choices.length > 0) {
          const findFieldId = fields.field_choices.find(
            choice => choice.name == fields.user_answer
          );
          if (findFieldId) {
            target_id = findFieldId.id;
          }
        }
        dynamic.push({
          id: fields.id,
          value: fields.user_answer,
          target_id: target_id
        });
      }
      let objTemp = [];

      for (const x of dynamic) {
        var findField = this.dynamicField.find(field => field.id == x.id);

        if (x.value && typeof x.value == "object") {
          if (x.value.length > 0) {
            for (const a of x.value) {
              const { id } = findField.field_choices.find(
                choice => choice.name == a
              );
              objTemp.push({
                id: x.id,
                value: a,
                target_id: id
              });
            }
          }
        } else {
          objTemp.push(x);
        }
      }
      this.form.friend_get_friend_url_key = this.$route.query.inviteKey || "";
      this.form.invite_user_guid = this.$route.query.user_guid || "";
      if (this.$route.query.noReceive) {
        delete this.form.friend_get_friend_url_key;
        delete this.form.invite_user_guid;
      }
      let payload = {
        custom_field: objTemp.filter(el => el.value),
        ...this.form
      };

      this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/Register`, payload)
        .then(async data => {
          if (data.result == 1) {
            this.$cookies.set("hourglass_token", data.detail.token);
            await this.pairUser();
            this.user_guid = data.detail.id;
            this.$cookies.remove("modalShowId");
            if (this.customField.length > 0) {
              await this.updateCustomField();
            } else {
              this.isLoading = false;
              if (this.$route.query.redirect) {
                if (
                  this.$route.query.redirect.indexOf("http://") == 0 ||
                  this.$route.query.redirect.indexOf("https://") == 0
                ) {
                  return (window.location.href = this.$route.query.redirect);
                } else {
                  if (this.$route.query.redirect.includes("invite")) {
                    this.$cookies.set("popupModalRegister", true);
                    return this.$router.push("/profile");
                  }
                  return this.$router.push({
                    path: this.$route.query.redirect,
                    query: { ...this.$route.query }
                  });
                }
              } else {
                this.$cookies.set("popupModalRegister", true);
                this.$router.push("/profile");
              }
            }
          } else {
            this.isLoading = false;
            this.disableBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
          }
        })
        .catch(err => {
          console.log(err.message);
          this.disabledBtn = false;

          this.successMessage = "เกิดข้อผิดพลาดไม่สามารถแก้ไขข้อมูลได้";

          this.$refs.modalError.show();
          this.isLoading = false;
        });
    },
    async pairUser() {
      let payload = {
        registerTypeId: 2,
        socialProvider: "line",
        socialId: this.form.SocialId,
        telephone: this.form.telephone
      };
      const response = await this.$axios.post(
        `${process.env.VUE_APP_API}/api/v1/user/pair`,
        payload
      );
      this.$cookies.set("hourglass_token", response.detail.token);
      return response;
    },
    async updateCustomField() {
      let body = {
        user_guid: this.user_guid,
        admin_user_guid: this.user_guid,
        question_list: this.questionList
      };
      await this.$axios
        .put(
          `${process.env.VUE_APP_API}/api/v1/User/update_custom_answer`,
          body
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            if (this.$route.query.redirect) {
              if (
                this.$route.query.redirect.indexOf("http://") == 0 ||
                this.$route.query.redirect.indexOf("https://") == 0
              ) {
                return (window.location.href = this.$route.query.redirect);
              } else {
                if (this.$route.query.redirect.includes("invite")) {
                  return this.$router.push("/profile");
                }
                return this.$router.push({
                  path: this.$route.query.redirect,
                  query: { ...this.$route.query }
                });
              }
            } else {
              this.$cookies.set("popupModalRegister", true);
              this.$router.push("/profile");
            }
          } else {
            this.disableBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
          }
        });
    },
    async getBranch(branch_id) {
      this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/Reward/redeem_coupon/${branch_id}`
        )
        .then(
          async data => {
            this.isLoading = false;
            if (data.result == 1) {
              var branchList = [];
              for (const { branch } of data.detail) {
                const filter = branch.filter(el => el.is_check);
                branchList.push(...filter);
              }
              this.branchList = branchList;
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    }
  }
};
</script>

<style lang="scss" scoped></style>
